"use strict";

$(
	function() {
		var $midLine = $('.top .middle-line');
		try {
			app.fixTop = $midLine.offset().top + $midLine.outerHeight() + $('._>.top .center-part .menu').height();
		} catch(e) {

		}

		$('textarea[name="custom[_Comments]"]').attr('maxlength', 280);

		app.assignNumberControl();
		app.setSelectedLink();
		app.addRegisterCheckBoxes();
		app.attachSvgEvents();
		app.setBackUrls();
		app.addCoverSpan();
		app.hamburger.init({
			look: 1
		});
		app.topMenu.init();
		if(!app.isMobile()) {
			$( window ).scroll(
				function(){
					app.floatingHeader();
				}
			);
			app.floatingHeader();
		}
		app.initializeScrollButton();
		app.assignProductOver();

		$('.menu > ul > li').addClass('schema-brd-color-over');
		$('.top a[href=""]').click(
			function(e) {
				e.preventDefault();
			}
		);

		$('.any-file.video-mp4').click(
			function(e) {
				e.preventDefault();
				var href = $(this).attr('href');
				//onloadeddata="alert(1)"
				var html = '<video controls autoplay src="' + href + '">';
				html += '</video>';

				app.alert(
					'<div class="udu__video-wrapper">' + html + '</div>',
					function () {
						setTimeout(
							function() {
								$('.udu__video-wrapper').remove();
							},
							400
						)
					},
					{
						cssClass: 'fullMode udu__auto-width',
						okTitle: app.lang.selectWord('Close', 'Proche')
					}
				);
			}
		);
		app.makeProdDetail();
		app.addChangeInfoLocButton();
		app.assignSocialIconsModal();
	}
);

var app = {
	_VERSION: 2,
	_vars: {
		_customFonts: {
			Linearicons: true
		},
		putAttributesToProdItemsDirectly: true,
		uduProdListOrderPlaseSelector: '.div-prod-btn',
		v: 2,
		baseEvents: {
			'udu_BEFORE-ATTACH-BLOCK-ADDED': function(e) {
				e.data.sel = '#add-information';
			},
			'udu-IN-STOCK-MSG-BEFORE-SET': function(e) {
				if (e.data.inv === 0 && app._vars.PRE_ORDER) {
					e.data.msg = app.lang.selectWord('PRE-ORDER', 'PRÉ-COMMANDER');
				}
			},
			'udu_INVENTORY-SET': function (e) {
				var $li = e.data.$li;
				if (!$li) {
					return true;
				}
				var qty = Number(e.data.avlQnt);
				var $descr = $li.find('.udu__mult-opotion-descr');

				if (!$descr.data('ini-done')) {
					$descr.data('ini-done', 'Y');
					$descr.data('ini-html', $descr.html());
				}
				var desc = $descr.data('ini-html');
				var $div = $li.find('.udu__mult-with-descr');
				if (qty > 0) {
					var msg = app.lang.selectWord('In Stock', 'En stock');
				} else if (Number(qty) === 0 && String($div[0].className).indexOf('udu__sys-pre_order') > 0) {
					msg = app.lang.selectWord('FUTURE WAVE', 'FUTURE WAVE');
					if (desc) {
						msg += '<br/>' + desc;
					}
				} else {
					msg = app.lang.selectWord('Out Of Stock', 'En rupture de stock');
					if (desc) {
						msg += '<br/>' + desc;
					}
				}
				$descr.html(msg).addClass('_done');
			},
			'udu_BEFORE-LIST-INVENTORY-SET': function(e) {
				var info = e.data.info;
				var inv = e.data.inv;
				var html = '';
				if (inv > 0) {
					html = app.lang.selectWord('In Stock', 'En stock');
				} else if (Number(inv) === 0 && info.PRE_ORDER === '1') {
					html = app.lang.selectWord('PRE-ORDER', 'PRÉ-COMMANDER');
				} else {
					html = app.lang.selectWord('Out Of Stock', 'En rupture de stock');
				}
				e.data.html = '<div class="udu__list-inv">' + html + '</span></div>';
			}
		}
	},
	responsiveMode: true,
	indexLinkPos : 0,
	messages : {},
	productAvailableQuantityUrl : null,
	optionsWereInitialized : false,
	showVideo: function() {

	},
	setWavePlan: function() {
		var lang = app.getLang();

		if (lang === 'fr') {
			var $atr = $('._ .udu-attr-wave_plan_link_fr .udu-attr-content');
		} else {
			$atr = $('._ .udu-attr-wave_plan_link .udu-attr-content');
		}
		if ($atr.length === 0) {
			return false;
		}
		$('.product-view .description').append('<div class="WavePlanDiv"><a href="" target="_blank">' + app.lang.selectWord('View Wave Plan', 'Voir planification des vagues') + '</a></div>');
		$('.WavePlanDiv a').attr('href', app.utils.str.clear($atr.html()));
	},
	setMyGroups: function(arr) {
		var $fld = $('fieldset').find('input[name=TERMS_OF_PAYMENT],select[name=TERMS_OF_PAYMENT]');
		if (arr.length === 0) {
			$fld.addClass('udu__need-display');
			return false;
		}
		var html = '<div class="udu__my-roles">';
		for (var i = 0; i < arr.length; i++) {
			html += '<div>' + arr[i] + '</div>';
		}
		html += '</div>';

		$fld.after(html);
	},
	addChangeInfoLocButton: function() {
		var $place = $('#customer-info .tools');
		if ($place.length === 0) {
			return false;
		}
		$place.addClass('udu__defaultLocationsPlace-2');
		app.getScript(
			['style-def.loc.css', 'https://cdn.uducat.com/static/esb/js/main.def.loc.js'],
			function(data) {
				app.events.addListener(
					'udu-DEFAULT-LOCATION-SELECTED',
					function(e) {
						var data = e.data.data;
						app.getInfo(
							'customer-info',
							{
								toJSON: true
							},
							function(userData) {
								for (var i in data) {
									var key = i.replace('AddressLine', 'Address');
									if (i.indexOf('shipping') === 0 && userData.data.hasOwnProperty(key) && data[i]) {
										userData.data[key] = data[i];
									}
								}

								var tmp = ['billingAddress', 'shippingAddress'];

								for (i = 1; i <= 2; i++) {
									for (var a = 0; a < 2; a++) {
										userData.data[tmp[a] + 'Line' + i] = userData.data[tmp[a] + i];
									}
								}
								$.post(
									userData.updateAddressURL,
									userData.data,
									function() {
										app.getInfo(
											'customer-info-html',
											function(html) {
												var $place = $('#customer-info .info-override');
												$('body').append('<div class="hidden" id="customer-info-dummy"></div>');
												var $dummy = $('#customer-info-dummy');
												$dummy.html(html);
												html = $dummy.find('.info-override').html();
												$place.html(html);
												$dummy.remove();
												app.utils.effects.blinkIt($place);
											}
										)
									}
								)
							}
						);
					}
				);
				app.defLoc.init({
					onlyIfExists: true
				});
			}
		)
	},
	goToSubCat: function(item) {
		var $this = $(item);
		var $op = $this.find('option:selected');
		var url = $op.data('url');
		if (url) {
			app.url.goTo(url, true);
		}
	},
	fixSubCats: function() {
		var $subCat = $('.udu__sub-cats');
		if ($subCat.length === 1) {
			$('.product-list-top-part').append($subCat).addClass('udu__with-cats');
			$subCat.removeClass('hidden');
		}

	},
	showSearch : function() {
		if ($('.li-search .ul-mega-1').hasClass('hidden')) {
			$('.li-search').addClass('hover');
			$('.li-search .ul-mega-1, .li-search .delimiter').removeClass('hidden');
		} else {
			$('.li-search').removeClass('hover');
			$('.li-search .ul-mega-1, .li-search .delimiter').addClass('hidden');
		}
	},
	runIndexCarusel: function() {
		app.runCarusel({
			innerHTML: '<div class="zoomer"><div class="inner"></div></div>'
		});
		$('.jcarousel-list li').each(
			function() {
				var href = $(this).find('.image a').attr('href');
				$(this).find('.zoomer').click(
					function() {
						document.location = href;
					}
				)
			}
		)
	},
	doBeforePriceSliderShown: function(trg) {
		var color = app.getClassColor('schema-color');
		$('.noUi-connect').css('background-color', color);
	},
	makeProdDetail: function() {
		app.utils.scripts.initOwlSlider(
			function() {
				app.owlSlider.makeProdDetail();
			}
		);
	},
	assignSocialIconsModal: function () {
		$('.fblock-1 div a').each(
			function() {
				var $this = $(this);
				$this.on('click', function(e) {
					e.preventDefault();
					var url = $this.attr('href');
					app.confirmMsg(
						'modalText',
						function() {
							window.open(url, '_blank');
						},
						null,
						{
							cssClass: 'fullMode udu__hasbro-blue-window',
							yesTitle: app.lang.selectWord('Continue', 'Continuer'),
							noTitle: app.lang.selectWord('Go Back', 'Revenir')
						}
					);
				});
			}
		)
	}
};